import React, { useMemo, CSSProperties } from 'react';
import styled from 'styled-components';

import { ClientApp } from 'common/model/ClientApp';
import { Usage } from 'common/model/Usage';

import { CustomerInformation } from 'bmw/common/components/CustomerInformation';
import { Damages } from 'bmw/common/components/Damages';
import { ExcessMileage } from 'bmw/common/components/ExcessMileage';
import { GrandTotal } from 'bmw/ewt/components/GrandTotal';
import { PDFHeader } from 'bmw/ewt/components/PDFHeader';
import { PretermLegalText } from 'bmw/common/components/PretermLegalText';
import { PretermReferencePage } from 'bmw/common/components/PretermReferencePage';
import { Summary } from 'bmw/common/components/Summary';
import { getTotalCostOptions } from 'bmw/common/helpers/getTotalCostOptions';
import { PoppinsHeader, BMWHeader } from 'bmw/common/helpers/globalStyles';
import { DamageSection } from 'bmw/common/model/DamageSection';

import 'bmw/common/components/styles.css';

import { AcknowledgmentSection } from './components/AcknowledgmentSection';
import { VehicleInfo } from 'common/model/VehicleInformation';

interface Props {
  vehicleInfo?: VehicleInfo;
  leaseProtectionBenefitAmount: number;
  disclosureId: string | undefined;
  currentMileage: number | undefined;
  damageSections: DamageSection[];
  totalMileageCharge: number;
  usage: Usage | undefined;
  isPdfRendering: boolean;
  dealershipName: string;
  clientApp: ClientApp;
}

export const EWTForm: React.FC<Props> = ({
  vehicleInfo,
  leaseProtectionBenefitAmount,
  disclosureId,
  currentMileage,
  damageSections,
  isPdfRendering,
  dealershipName,
  totalMileageCharge,
  usage,
  clientApp
}) => {
  const { bodyAssessmentTotal, partsChargesTotal, optionChargesTotal, dispositionFeeAmount, grandTotal } = useMemo(
    () =>
      getTotalCostOptions({
        damageSections,
        leaseProtectionBenefitAmount,
        totalMileageCharge,
        usage
      }),
    [damageSections, leaseProtectionBenefitAmount, totalMileageCharge, usage]
  );

  const containerVisibilityStyle: CSSProperties = { visibility: !vehicleInfo || !usage ? 'hidden' : 'visible' };

  return (
    <FormContainer>
      <ContentContainer>
        <HeaderContainer style={containerVisibilityStyle}>
          <PDFHeader />
          <div>
            <InspectionTitle isPdfRendering={isPdfRendering}>Customer Pre-Term Self Inspection Form</InspectionTitle>
            <HeaderInfoContainer>
              <HeaderTitle isPdfRendering={isPdfRendering}>Originating Dealership</HeaderTitle>
              {dealershipName && (
                <HeaderValue isPdfRendering={isPdfRendering} data-testid="inspection-center-name">
                  {dealershipName}
                </HeaderValue>
              )}
            </HeaderInfoContainer>
          </div>
        </HeaderContainer>
        <div style={containerVisibilityStyle}>
          <CustomerInformation
            usage={usage}
            vehicleInfo={vehicleInfo}
            isPdfRendering={isPdfRendering}
            isConsumerInspection
          />

          {usage && currentMileage !== undefined && (
            <ExcessMileage
              currentMileage={currentMileage}
              initialMileage={usage?.initialMileage}
              contractMileage={usage?.contractMileage}
              totalMileageCharge={totalMileageCharge}
              isPdfRendering={isPdfRendering}
              isConsumerInspection
            />
          )}
          <Damages
            sections={damageSections}
            hasLeaseProtectionBenefit={usage?.leaseProtectionBenefit}
            isConsumerInspection
            isPdfRendering={isPdfRendering}
          />
          {usage && (
            <Summary
              clientApp={clientApp}
              dispositionFeeAmount={dispositionFeeAmount}
              totalMileageCharge={totalMileageCharge}
              leaseProtectionBenefitAmount={leaseProtectionBenefitAmount}
              bodyAssessmentTotal={bodyAssessmentTotal}
              partsChargesTotal={partsChargesTotal}
              optionChargesTotal={optionChargesTotal}
              isConsumerInspection
              isPretermInspection
              isPdfRendering={isPdfRendering}
            />
          )}
        </div>
      </ContentContainer>
      <div style={containerVisibilityStyle}>
        <GrandTotal grandTotal={grandTotal} isPdfRendering={isPdfRendering} />
      </div>
      <ContentContainer>
        <div style={containerVisibilityStyle}>
          <PretermLegalText isConsumerInspection isPdfRendering={isPdfRendering} />
        </div>
      </ContentContainer>
      {isPdfRendering && disclosureId && currentMileage !== undefined && (
        <AcknowledgmentSection disclosureId={disclosureId as string} />
      )}
      <ContentContainer style={containerVisibilityStyle}>{isPdfRendering && <PretermReferencePage />}</ContentContainer>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  font-family: Roboto;
  white-space: pre-line;
  text-align: left;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;

  @media print, screen and (max-width: 1200px) {
    max-width: 80%;
  }

  @media print, screen and (max-width: 960px) {
    max-width: 680px;
  }
`;

const HeaderContainer = styled.div`
  padding: 15px 0;
  max-width: 1200px;
  margin: 0 auto;
`;

const InspectionTitle = styled.div<{
  isPdfRendering?: boolean;
}>`
  ${props => (props.isPdfRendering ? BMWHeader : PoppinsHeader)}
  padding: 16px 0;
  color: black;
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.p<{
  isPdfRendering?: boolean;
}>`
  font-size: 14px;
  font-family: ${props => (props.isPdfRendering ? 'BMWGroupCondensed-Regular' : 'Roboto')}
  color: #6a6a6a;
`;

const HeaderValue = styled.p<{
  isPdfRendering?: boolean;
}>`
  font-size: 16px;
  color: black;
  margin-left: 10px;
  font-family: ${props => (props.isPdfRendering ? 'BMWGroupCondensed-Regular' : 'Roboto')};
`;
