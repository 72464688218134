import React from 'react';
import { formatCurrency } from 'common/helpers/formatCurrency';

export const getPriceView = (
  chargeable: boolean,
  price: number,
  priceStyle: React.CSSProperties,
  strikeThroughPriceStyle: React.CSSProperties
) => {
  if (chargeable) {
    return <div style={priceStyle}>{formatCurrency(price)}</div>;
  } else {
    return (
      <>
        {price !== 0.0 ? (
          <div style={strikeThroughPriceStyle} data-testid="strike-through-price">
            {formatCurrency(price)}
          </div>
        ) : null}
        <div style={priceStyle}>{formatCurrency(0.0)}*</div>
      </>
    );
  }
};
