import { BuildInfo, VehicleInfo } from './model/VehicleInformation';

export const mapBuildInfoToVehicleInfo = (payload: BuildInfo): VehicleInfo => {
  return {
    year: payload.year || 0,
    vin: payload.vin,
    make: payload.make || '',
    model: payload.model || '',
    trim: payload.trim || '',
    bodyTypePrimary: payload.bodyTypePrimary || ''
  };
};
