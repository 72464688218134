import React from 'react';
import styled from 'styled-components';

import { PageContainer } from 'ewt/styles';
import { InspectionChargesDetailsType } from 'ewt/requests/chargesAPI/types';
import '../styles.css';
import { WearAndUse } from './WearAndUse';

interface Props {
  inspectionChargesDetails: InspectionChargesDetailsType | undefined;
  isError: boolean;
  inspectionType?: string;
  vin: string;
  dealerName: string;
}

export const EWTForm: React.FC<Props> = ({ inspectionChargesDetails, isError, inspectionType, vin, dealerName }) => {
  return (
    <PageContainer data-testid="tfs-ewt-container">
      {inspectionType && (
        <InspectionTypeContainer>
          <InspectionTypeText>
            {dealerName} {inspectionType} Report {vin}
          </InspectionTypeText>
        </InspectionTypeContainer>
      )}
      {inspectionChargesDetails && (
        <>
          <WearAndUse inspectionChargesDetails={inspectionChargesDetails} />
          <FooterContainer>
            <FooterText>
              Please note that this is not an invoice and the estimated charges are based upon damage described at time
              of inspection.
            </FooterText>
          </FooterContainer>
        </>
      )}
      {isError && <p style={{ color: 'red' }}>Unable to load the report.</p>}
    </PageContainer>
  );
};

const InspectionTypeText = styled.span`
  font-family: Inter;
  font-weight: 700;
  font-size: 14px
  color: black;
`;

const InspectionTypeContainer = styled.div`
  background-color: #f5f5f6;
  border-bottom: 1px solid #666666;
  min-height: 50px;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
`;

export const FooterContainer = styled.div`
  margin: 20px 0px 20px 0px;
  padding-left: 5px;
`;

const FooterText = styled.div`
  color: #8c1c58;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
`;
