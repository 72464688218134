import React from 'react';
import styled from 'styled-components';

import { ClientApp } from 'common/model/ClientApp';
import { Usage } from 'common/model/Usage';
import { VehicleInfo } from 'common/model/VehicleInformation';

import { CustomerInformation } from 'bmw/common/components/CustomerInformation';
import { Damages } from 'bmw/common/components/Damages';
import { ExcessMileage } from 'bmw/common/components/ExcessMileage';
import { LegalText } from 'bmw/common/components/LegalText';
import { OdoStatement } from 'bmw/common/components/OdoStatement';
import { PDFHeader } from 'bmw/leif/components/PDFHeader';
import { PretermLegalText } from 'bmw/common/components/PretermLegalText';
import { ReferencePage } from 'bmw/common/components/ReferencePage';
import { Summary } from 'bmw/common/components/Summary';
import { DamageSection } from 'bmw/common/model/DamageSection';

import { AcknowledgmentSection } from './components/AcknowledgmentSection';

import 'bmw/common/components/styles.css';

interface Props {
  vehicleInfo: VehicleInfo | undefined;
  leaseProtectionBenefitAmount: number;
  disclosureId: string | undefined;
  currentMileage: number | undefined;
  damageSections: DamageSection[];
  totalMileageCharge: number;
  usage: Usage | undefined;
  isPdfRendering: boolean;
  dealershipName: string;
  isPreterm: boolean;
  clientApp: ClientApp;
}

export const LeifForm: React.FC<Props> = ({
  vehicleInfo,
  leaseProtectionBenefitAmount,
  disclosureId,
  currentMileage,
  damageSections,
  isPdfRendering,
  dealershipName,
  totalMileageCharge,
  usage,
  isPreterm,
  clientApp
}) => {
  const headerTitle = clientApp === ClientApp.Dealer ? 'Inspection Center' : 'Originating Dealer';

  return (
    <LeifFormContainer>
      <HeaderContainer>
        {isPdfRendering ? (
          <PDFHeader dealershipName={dealershipName} isPreterm={isPreterm} />
        ) : (
          <>
            <HeaderTitle>{headerTitle}</HeaderTitle>
            <HeaderValue data-testid="inspection-center-name">{dealershipName}</HeaderValue>
          </>
        )}
      </HeaderContainer>
      <div
        style={{
          visibility: !vehicleInfo || !usage ? 'hidden' : 'visible'
        }}
      >
        <CustomerInformation usage={usage} vehicleInfo={vehicleInfo} isPdfRendering={isPdfRendering} />
        <Damages sections={damageSections} hasLeaseProtectionBenefit={usage?.leaseProtectionBenefit} />
        {usage && currentMileage !== undefined && (
          <ExcessMileage
            currentMileage={currentMileage}
            initialMileage={usage?.initialMileage}
            contractMileage={usage?.contractMileage}
            totalMileageCharge={totalMileageCharge}
          />
        )}
        {usage && (
          <Summary
            clientApp={clientApp}
            isPretermInspection={isPreterm}
            dispositionFeeAmount={usage?.dispositionFeeAmount}
            totalMileageCharge={totalMileageCharge}
            leaseProtectionBenefitAmount={leaseProtectionBenefitAmount}
            bodyAssessmentTotal={damageSections[0]?.totalCost}
            partsChargesTotal={damageSections[1]?.totalCost}
            optionChargesTotal={damageSections[2]?.totalCost}
          />
        )}
        {isPreterm ? <PretermLegalText /> : <LegalText />}
      </div>
      {isPdfRendering && disclosureId && currentMileage !== undefined && (
        <>
          <AcknowledgmentSection disclosureId={disclosureId} isPreterm={isPreterm} />
          {!isPreterm && (
            <OdoStatement
              vehicleInfo={vehicleInfo}
              usage={usage}
              disclosureId={disclosureId}
              currentMileage={currentMileage}
            />
          )}
        </>
      )}
      {isPdfRendering && <ReferencePage />}
    </LeifFormContainer>
  );
};

const LeifFormContainer = styled.div`
  font-family: BMWGroupCondensed-Regular;
  max-width: 1200px;
  margin: 0 auto;

  @media print, screen and (max-width: 960px) {
    max-width: 680px;
  }
`;

const HeaderContainer = styled.div`
  padding: 15px 0px 20px 8px;
`;

const HeaderTitle = styled.div`
  font-size: 19px;
  color: black;
`;

const HeaderValue = styled.div`
  font-size: 19px;
  color: black;
  font-family: BMWGroupCondensed-Bold;
`;
