import React, { useEffect, useState } from 'react';

import { PageContainer } from './styles';

import { initializeAxios } from 'common/api/setup';
import { fetchExpandedDisclosureById } from 'common/api/disclosure/fetchExpandedDisclosureById';
import { fetchUsageById } from 'common/api/fetchUsageById';

import { getInspectionChargesDetails } from 'ewt/requests/chargesAPI/getInspectionChargesDetails';
import { InspectionChargesDetailsType, ChargesTypes } from 'ewt/requests/chargesAPI/types';

import { Usage } from 'common/model/Usage';
import { WebViewWindow } from 'common/model/WebViewWindow';
import { ExpandedDisclosure } from 'common/model/Disclosure/ExpandedDisclosure';
import { ExpandedGFBResponse } from 'common/model/Disclosure/ExpandedGFBResponse';

import { getQueryParams } from 'common/utils/getQueryParams';

import { StateDisclosure } from './components/StateDisclosure';
import { InspectionSummary } from './components/InspectionSummary';
import { ConditionReport } from './components/ConditionReport/ConditionReport';

import { ClientApp } from 'common/model/ClientApp';
import { logger } from 'common/logger/logger';
import { setDisclosureIdMetadata, setVinMetadata } from 'common/logger/debugMetadata';
import { DisclosureDocumentType } from 'common/model/Disclosure/DisclosureDocument';
import { getFinalCharges } from './helpers/getFinalCharges';
import './styles.css';
import { deriveVehicleInfo } from '../common/deriveVehicleInfo';
import { VehicleInfo } from 'common/model/VehicleInformation';

type QueryParams = {
  authToken: string;
  disclosureId: string;
  authTokenType?: string;
  remoteInspection?: string;
  usageId?: string;
};

const clientApp = ClientApp.Consumer;

export const EWTContainer: React.FC = () => {
  const [expandedDisclosure, setExpandedDisclosure] = useState<ExpandedDisclosure>();
  const [usage, setUsage] = useState<Usage>();
  const [inspectionChargesDetails, setInspectionChargesDetails] = useState<InspectionChargesDetailsType>();
  const [revisedChargeDetails, setRevisedChargesDetails] = useState<InspectionChargesDetailsType>();
  const [addedChargeDetails, setAddedChargesDetails] = useState<InspectionChargesDetailsType>();
  const [isRemoteInspectionEnabled, setIsRemoteInspectionEnabled] = useState(false);
  const [isFinalPdf, setIsFinalPdf] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>();

  const reactNativeWebView = (window as Partial<WebViewWindow & typeof globalThis>).ReactNativeWebView;

  useEffect(() => {
    logger.info('ewt:render');

    const { authToken, authTokenType, disclosureId, usageId, remoteInspection } = getQueryParams<QueryParams>();

    if (!authToken || !disclosureId) {
      logger.error('EWTContainer:initialize:invalidParams', {
        disclosureId
      });

      throw new Error('Invalid parameters');
    }

    setDisclosureIdMetadata(disclosureId);

    if (remoteInspection === 'true') {
      setIsRemoteInspectionEnabled(true);
    }

    initializeAxios(authToken, clientApp, authTokenType);

    async function initialize() {
      try {
        const expandedDisclosureResponse = await fetchExpandedDisclosureById(disclosureId);
        const vin = expandedDisclosureResponse.unit.vin;
        const vehicleInfo = await deriveVehicleInfo(vin, clientApp);

        setVehicleInfo(vehicleInfo);

        setVinMetadata(vin);
        expandedDisclosureResponse.responses.sort(
          (a: ExpandedGFBResponse, b: ExpandedGFBResponse) => Number(a.question.id) - Number(b.question.id)
        );

        setExpandedDisclosure(expandedDisclosureResponse);

        const pretermPDF = expandedDisclosureResponse.disclosureDocuments.items.find(
          document => document.type === DisclosureDocumentType.pretermPdf
        );

        if (pretermPDF?.pdf?.href) {
          setIsFinalPdf(true);
        }

        const [usageResponse, inspectionChargesDetailsResponse] = await Promise.all([
          fetchUsageById(usageId),
          getInspectionChargesDetails(expandedDisclosureResponse.guid)
        ]);

        usageResponse && setUsage(usageResponse);

        const [inspectionChargesDetails] = inspectionChargesDetailsResponse as InspectionChargesDetailsType[];

        setInspectionChargesDetails(inspectionChargesDetails);

        if (inspectionChargesDetailsResponse && inspectionChargesDetailsResponse.length > 1) {
          // Final charges are charges, which were reviewed by remote inspector.
          const finalChargesDetails = inspectionChargesDetailsResponse.find(({ type }) => type === ChargesTypes.Final);

          if (finalChargesDetails) {
            const { revisedDamages, addedDamages } = getFinalCharges(
              expandedDisclosureResponse,
              finalChargesDetails as InspectionChargesDetailsType
            );

            setRevisedChargesDetails({
              ...(finalChargesDetails as InspectionChargesDetailsType),
              charges: revisedDamages
            });
            setAddedChargesDetails({
              ...(finalChargesDetails as InspectionChargesDetailsType),
              charges: addedDamages
            });
          }
        }

        reactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'loaded',
            message: 'EWT report is loaded'
          })
        );

        setError(false);
      } catch (error) {
        reactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'error',
            message: 'EWT report is unavailable'
          })
        );

        logger.error('ewt:initialize:error', { error });
        setError(true);
      }
    }

    initialize();
  }, [reactNativeWebView]);

  const filteredInspectionChargesDetails = inspectionChargesDetails?.charges.map(charge => {
    charge.damages = charge.damages.filter(damage => !(damage.type === 'Excess Mileage Charge' && damage.price === 0));
    return charge;
  });

  if (inspectionChargesDetails && filteredInspectionChargesDetails) {
    inspectionChargesDetails.charges = filteredInspectionChargesDetails;
  }

  const customerStateCode = usage?.address?.stateProvinceCode ?? '';

  return (
    <PageContainer data-testid="ewt-container">
      {isFinalPdf && (
        <InspectionSummary
          title="Revised Inspection Report"
          vehicleInfo={vehicleInfo}
          expandedDisclosure={expandedDisclosure}
          inspectionChargesDetails={revisedChargeDetails}
          addedCharges={addedChargeDetails}
          isInspectionVerified={!!expandedDisclosure?.alternateResponses}
        />
      )}
      <InspectionSummary
        title={isRemoteInspectionEnabled ? 'Preliminary Inspection Report' : 'Inspection Report'}
        vehicleInfo={vehicleInfo}
        expandedDisclosure={expandedDisclosure}
        inspectionChargesDetails={inspectionChargesDetails}
      />
      {error && <p style={{ color: 'red' }}>Unable to load the report.</p>}
      <StateDisclosure stateCode={customerStateCode} />
      {expandedDisclosure && (
        <ConditionReport disclosureId={expandedDisclosure.guid} env={process.env.REACT_APP_CAI_ENV as string} />
      )}
    </PageContainer>
  );
};
