import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useWindowDimensions } from 'common/utils/useWindowDimensions';
import { RibbonIndicator } from 'common/components/RibbonIndicator';
import { formatCurrency } from 'common/helpers/formatCurrency';
import { DamageSection } from '../model/DamageSection';
import {
  TopLine,
  DefaultRobotoText,
  DefaultBMWText,
  PoppinsHeader,
  BMWHeader,
  BMWPdfHeader
} from '../helpers/globalStyles';

interface Props {
  sections: DamageSection[];
  hasLeaseProtectionBenefit: boolean | undefined;
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}

export const Damages: React.FC<Props> = ({
  sections,
  hasLeaseProtectionBenefit,
  isConsumerInspection,
  isPdfRendering
}) => {
  const { width } = useWindowDimensions();
  const isMediumTablet = width >= 680;

  const { SectionTitle, TextElement, Quantity, Notes, TitleCost } = isConsumerInspection
    ? {
        SectionTitle: isPdfRendering ? SectionHeaderTitleConsumerPDF : SectionHeaderTitleConsumer,
        TextElement: isPdfRendering ? ConsumerPDFStyleAnswerText : ConsumerStyleAnswerText,
        Quantity: isPdfRendering ? ConsumerStylePDFQuantity : ConsumerStyleQuantity,
        Notes: isPdfRendering ? ConsumerPDFStyleAnswerNotes : ConsumerStyleAnswerNotes,
        TitleCost: isPdfRendering ? PDFHeaderTitleCost : HeaderTitleCost
      }
    : {
        SectionTitle: SectionHeaderTitle,
        TextElement: AnswerText,
        Quantity: AnswerQuantity,
        Notes: AnswerNotes,
        TitleCost: HeaderTitleCost
      };

  return (
    <>
      {sections.map(section => (
        <Fragment key={section.title}>
          <SectionHeaderContainer isConsumerInspection={isConsumerInspection}>
            <SectionTitle>
              <SectionHeaderTitleText>
                <div>{isConsumerInspection ? section.title : section.title.toUpperCase()}</div>
                {isConsumerInspection ? (
                  <TitleCost>{formatCurrency(section.totalCost)}</TitleCost>
                ) : (
                  <div>{formatCurrency(section.totalCost)}</div>
                )}
              </SectionHeaderTitleText>
            </SectionTitle>
          </SectionHeaderContainer>
          {section.questions.map(question => (
            <SectionQuestionContainer key={question.questionText} isConsumerInspection={isConsumerInspection}>
              <QuestionContainer>
                {isConsumerInspection ? (
                  <ConsumerStyleQuestion>{question.questionText}</ConsumerStyleQuestion>
                ) : (
                  <Question>{question.questionText}</Question>
                )}
                <Cost isConsumerInspection={isConsumerInspection} isPdfRendering={isPdfRendering}>
                  {formatCurrency(question.cost)}
                </Cost>
              </QuestionContainer>
              {question.answers.map((answer, index) => (
                <Fragment key={`${answer.value}${index}`}>
                  <AnswerRow isConsumerInspection={isConsumerInspection}>
                    {hasLeaseProtectionBenefit ? (
                      isMediumTablet ? (
                        <>
                          <TextElement>{answer.value}</TextElement>
                          <Quantity>{answer.quantity}</Quantity>
                          {answer.leaseProtection && (
                            <MediumTabletWrapper>
                              <RibbonIndicator text="LEASE PROTECTION" />
                            </MediumTabletWrapper>
                          )}
                        </>
                      ) : (
                        <>
                          <div>
                            <TextElement>{answer.value}</TextElement>
                            {answer.leaseProtection && <RibbonIndicator text="LEASE PROTECTION" />}
                          </div>
                          <Quantity>{answer.quantity}</Quantity>
                        </>
                      )
                    ) : (
                      <>
                        <TextElement>{answer.value}</TextElement>
                        <Quantity>{answer.quantity}</Quantity>
                      </>
                    )}
                  </AnswerRow>
                  <Notes>{answer.notes}</Notes>
                </Fragment>
              ))}
            </SectionQuestionContainer>
          ))}
        </Fragment>
      ))}
    </>
  );
};

const MediumTabletWrapper = styled.div`
  margin-left: auto;
`;

const SectionHeaderContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  width: 100%;
  padding: ${props => (props.isConsumerInspection ? '15px 0' : '12px 10px')};
  ${props => (props.isConsumerInspection ? TopLine : 'background-color: #eeefea; margin-bottom: 12px;')}
`;

const SectionHeaderTitle = styled.div`
  ${BMWHeader}
  @media print {
    // hacky workaround to simulate "page-break-after: avoid" which is not supported https://caniuse.com/css-page-break
    page-break-inside: avoid;
    &:after {
      content: '/S';
      display: block;
      height: 100px;
      margin-bottom: -100px;
      opacity: 0;
    }
  }
`;

const SectionHeaderTitleConsumer = styled(SectionHeaderTitle)`
  ${PoppinsHeader}
`;

const SectionHeaderTitleConsumerPDF = styled(SectionHeaderTitle)`
  ${BMWPdfHeader}
`;

const SectionHeaderTitleText = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitleCost = styled.div`
  ${DefaultRobotoText}
  font-weight: 700;
`;

const PDFHeaderTitleCost = styled(HeaderTitleCost as any)`
  font-family: BMWGroupCondensed-Bold;
`;

const SectionQuestionContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  padding: ${props => (props.isConsumerInspection ? '0' : '3px 8px')};
  @media print {
    break-inside: avoid-page;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ConsumerStyleQuestion = styled.div`
  ${DefaultRobotoText}
  color: #6A6A6A;
  font-size: 14px;
  min-width: 70%;
`;

const Question = styled.div`
  color: #666666;
  font-size: 19px;
  font-family: BMWGroupCondensed-Regular;
  min-width: 70%;
`;

const Cost = styled.div<{
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}>`
  color: black;
  font-size: 19px;
  font-family: BMWGroupCondensed-Regular;
  ${props => (props.isConsumerInspection && !props.isPdfRendering ? DefaultRobotoText : '')}
`;

const AnswerRow = styled.div<{
  isConsumerInspection?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${props => (props.isConsumerInspection ? '10px 0 10px 20px' : '10px 7px 10px 40px')};
  @media print, screen and (min-width: 680px) {
    align-items: center;
    ${props => (props.isConsumerInspection ? 'margin-right: 0' : 'justify-content: flex-start')}
  }
`;

const ConsumerStyleAnswerText = styled(ConsumerStyleQuestion as any)`
  min-width: 50%;
`;

const ConsumerPDFStyleAnswerText = styled(ConsumerStyleAnswerText as any)`
  font-family: BMWGroupCondensed-Regular;
`;

const AnswerText = styled.div`
  color: black;
  font-size: 19px;
  font-family: BMWGroupCondensed-Regular;
  min-width: 50%;
`;

const ConsumerStyleQuantity = styled(ConsumerStyleQuestion as any)`
  color: black;
  font-size: 16px;
  min-width: auto;
`;

const ConsumerStylePDFQuantity = styled(ConsumerStyleQuantity as any)`
  font-family: BMWGroupCondensed-Regular;
`;

const AnswerQuantity = styled.div`
  color: black;
  font-size: 19px;
  font-family: BMWGroupCondensed-Regular;
`;

const ConsumerStyleAnswerNotes = styled.div`
  margin-left: 40px;
  margin-bottom: 10px;
  ${DefaultRobotoText}
  color: #6A6A6A;
  font-size: 14px;
`;

const ConsumerPDFStyleAnswerNotes = styled.div`
  margin-left: 40px;
  margin-bottom: 10px;
  ${DefaultBMWText}
  color: #6A6A6A;
  font-size: 14px;
`;

const AnswerNotes = styled.div`
  color: black;
  font-size: 19px;
  font-family: BMWGroupCondensed-Regular;
  margin-left: 80px;
  margin-bottom: 10px;
`;
