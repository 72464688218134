import React from 'react';
import styled from 'styled-components';

import { VehicleInfo } from 'common/model/VehicleInformation';
import { Usage } from 'common/model/Usage';
import { getTodayUTCDate } from '../helpers/getTodayUTCDate';
import { formatDate } from 'common/helpers/formatDate';
import { BMWHeader, PoppinsHeader, TopLine } from '../helpers/globalStyles';
import { VinNumber } from './VinNumber';
import {
  VehiclePropertiesListContainer,
  PropertiesListItemLastInRow,
  PropertiesListItemWholeRow,
  getListElements
} from './vehiclePropertiesListStyles';
import './styles.css';

interface Props {
  vehicleInfo: VehicleInfo | undefined;
  usage: Usage | undefined;
  isPdfRendering: boolean;
  isConsumerInspection?: boolean;
}

const todayUTCDate = getTodayUTCDate();
const inspectionDate = formatDate(todayUTCDate);

export const CustomerInformation: React.FC<Props> = ({ usage, vehicleInfo, isPdfRendering, isConsumerInspection }) => {
  const { Title, Value, PropertiesListItem } = getListElements({ isConsumerInspection, isPdfRendering });

  return (
    <CustomerInfoContainer isConsumerInspection={isConsumerInspection}>
      {isConsumerInspection ? (
        <ConsumerStyleSectionHeader isPdfRendering={isPdfRendering}>Customer and Vehicle</ConsumerStyleSectionHeader>
      ) : (
        <SectionHeader>Customer Information</SectionHeader>
      )}
      <VehiclePropertiesListContainer data-testid="vehicle-properties-list" isConsumerInspection={isConsumerInspection}>
        <PropertiesListItem>
          <Title>Name</Title>
          <Value>{usage?.contact?.firstName + ' ' + (usage?.contact?.lastName || '')}</Value>
        </PropertiesListItem>
        <PropertiesListItem>
          <Title>Account Number</Title>
          <Value>
            {usage?.contractCode && isPdfRendering
              ? usage.contractCode.replace(usage.contractCode.substring(0, 6), 'XXXXXX')
              : usage?.contractCode}
          </Value>
        </PropertiesListItem>
        <PropertiesListItemLastInRow isConsumerInspection={isConsumerInspection}>
          <Title>BMW Lease Protection</Title>
          <Value>{usage?.leaseProtectionBenefit ? 'Yes' : 'No'}</Value>
        </PropertiesListItemLastInRow>
        <PropertiesListItem>
          <Title>Year</Title>
          <Value>{vehicleInfo?.year}</Value>
        </PropertiesListItem>
        <PropertiesListItem>
          <Title>Make</Title>
          <Value>{vehicleInfo?.make}</Value>
        </PropertiesListItem>
        <PropertiesListItem>
          <Title>Model</Title>
          <Value>{vehicleInfo?.model}</Value>
        </PropertiesListItem>
        <PropertiesListItemWholeRow isConsumerInspection={isConsumerInspection}>
          <Title>VIN</Title>
          <VinNumber
            vin={vehicleInfo?.vin || ''}
            isProtected={isPdfRendering}
            isConsumerInspection={isConsumerInspection}
            isPdfRendering={isPdfRendering}
          />
        </PropertiesListItemWholeRow>
        <PropertiesListItem>
          <Title>Maturity Date</Title>
          <Value>{usage?.contractualEndDate ? formatDate(usage.contractualEndDate) : ''}</Value>
        </PropertiesListItem>
        <PropertiesListItem>
          <Title>Inspection Date</Title>
          <Value>{inspectionDate}</Value>
        </PropertiesListItem>
        <PropertiesListItemLastInRow isConsumerInspection={isConsumerInspection}>
          <Title>New or Pre-Owned</Title>
          <Value>{usage?.newOrPreowned}</Value>
        </PropertiesListItemLastInRow>
      </VehiclePropertiesListContainer>
    </CustomerInfoContainer>
  );
};

const CustomerInfoContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  ${props => (props.isConsumerInspection ? TopLine : '')}
`;

const SectionHeader = styled.div`
  width: 100%;
  background-color: #eeefea;
  padding: 12px 10px;
  ${BMWHeader}
  text-transform: uppercase;
`;

const ConsumerStyleSectionHeader = styled.div<{
  isPdfRendering?: boolean;
}>`
  width: 100%;
  padding-top: 15px;
  ${props => (props.isPdfRendering ? BMWHeader : PoppinsHeader)}
  color: black;
`;
