import React from 'react';
import styled from 'styled-components';

import { DefaultRobotoText, DefaultBMWText } from '../helpers/globalStyles';

interface Props {
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}

export const PretermLegalText: React.FC<Props> = ({ isConsumerInspection, isPdfRendering }) => {
  return (
    <LegalTextContainer
      data-testid="legal-text"
      isConsumerInspection={isConsumerInspection}
      isPdfRendering={isPdfRendering}
    >
      <br />
      <div>
        *The Disposition Fee is a charge included on the Lease Agreement which is used to defray the costs of preparing
        and selling the vehicle at the end of the lease (either scheduled or early termination) if the vehicle is
        returned. It is not due if the customer purchases the vehicle. As a courtesy to our loyal customers, the
        disposition fee will be waived if a customer or household member lease or finance a new BMW through BMW
        Financial Services within 12 months of the termination date, or if they have any active account with BMW
        Financial Services. The disposition fee amount may vary based on state guidelines.
      </div>
      <br />
      <div>
        The total charges listed on this form may be subject to change if a vehicle is determined to have sustained
        damages not listed on this form. Examples of such damage may include but are not limited to any substantial body
        damage that exceeds the parameters listed on this form, missing, misaligned or broken components, any major
        mechanical problem, improper repairs, hail damage or a badly damaged interior (multiple burns, rips or water
        damage). In this case, a third-party inspector must evaluate the vehicle.
      </div>
      <br />
      <div>
        The inspection form must reflect the condition of the vehicle at turn-in. If turn-in condition is significantly
        different, a re-inspection is required. The signature of both the customer and inspector are required for
        validation; however, PLEASE NOTE that the inspection is not complete until inspector uploads the required images
        as required by BMW Financial Services.{' '}
        {!isConsumerInspection && (
          <b>
            By signing this form, the customer and inspector acknowledge that they have read and agree with the
            assessment of this vehicle as itemized and in accordance with the parameters defined on this form.
          </b>
        )}
      </div>
      {isConsumerInspection && (
        <>
          <br />
          <div>
            <b>
              By signing this form, the customer acknowledges that they have read and agree with the assessment of this
              vehicle as itemized and in accordance with the parameters defined on this form.
            </b>
          </div>
        </>
      )}
    </LegalTextContainer>
  );
};

const LegalTextContainer = styled.div<{
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}>`
  color: #666666;
  font-size: 19px;
  padding: ${props => (props.isConsumerInspection ? '24px 0' : '10px 8px')};
  ${props => (props.isConsumerInspection ? (props.isPdfRendering ? DefaultBMWText : DefaultRobotoText) : '')}
`;
