import React, { useEffect, useMemo, useState } from 'react';

import { initializeAxios } from 'common/api/setup';
import { fetchDisclosureById } from 'common/api/disclosure/fetchDisclosureById';

import { logger } from 'common/logger/logger';
import { setDisclosureIdMetadata, setVinMetadata } from 'common/logger/debugMetadata';
import { ClientApp } from 'common/model/ClientApp';
import { fetchUsageById } from 'common/api/fetchUsageById';
import { Disclosure } from 'common/model/Disclosure/Disclosure';
import { Usage } from 'common/model/Usage';
import { getQueryParams } from 'common/utils/getQueryParams';
import { WebViewWindow } from 'common/model/WebViewWindow';

import { calculateLeaseProtectionBenefitAmount } from 'bmw/common/helpers/calculateLeaseProtectionBenefitAmount';
import { calculateTotalMileageCharge } from 'bmw/common/helpers/calculateTotalMileageCharge';
import { mapDamageSections } from 'bmw/common/helpers/mapDamageSections';
import { getBmwInspectionItems } from 'bmw/common/services/bmwInspectionService/bmwInspectionService';
import { InspectionItem } from 'bmw/common/services/bmwInspectionService/types';

import { LeifForm } from './LeifForm';
import { deriveVehicleInfo } from 'common/deriveVehicleInfo';
import { VehicleInfo } from 'common/model/VehicleInformation';

const clientApp = ClientApp.Dealer;

type LEIFQueryParams = {
  usageGuid: string;
  vin: string;
  authToken: string;
  authTokenType?: string;
  clientApp?: string;
  dealershipName: string;
  disclosureId: string;
  isPdfRendering: string;
  isPreterm: string;
};

export const LeifFormContainer = () => {
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>();
  const [usage, setUsage] = useState<Usage>();
  const [disclosure, setDisclosure] = useState<Disclosure>();
  const [bmwInspectionItems, setBmwInspectionItems] = useState<InspectionItem[]>();
  const [dealershipName, setDealershipName] = useState<string>('');
  const [totalMileageCharge, setTotalMileageCharge] = useState<number>(0);
  const [isPdfRendering, setIsPdfRendering] = useState<boolean>(false);
  const [isPreterm, setIsPreterm] = useState<boolean>(false);

  useEffect(() => {
    const {
      usageGuid,
      authToken,
      authTokenType,
      vin,
      dealershipName,
      disclosureId,
      isPdfRendering,
      isPreterm: isPretermParam
    } = getQueryParams<LEIFQueryParams>();
    document.title = vin && `Inspection Summary ${vin.substring(9)}`;

    setVinMetadata(vin);
    setDisclosureIdMetadata(disclosureId);
    logger.info('leif-form:render');

    const reactNativeWebView = (window as Partial<WebViewWindow & typeof globalThis>).ReactNativeWebView;

    if (authToken) {
      initializeAxios(authToken, clientApp, authTokenType);
    }
    setDealershipName(dealershipName || '');
    setIsPdfRendering(isPdfRendering === 'true');
    setIsPreterm(isPretermParam === 'true');

    const fetchLEIFFormData = () => {
      Promise.all([
        deriveVehicleInfo(vin, clientApp),
        fetchUsageById(usageGuid),
        fetchDisclosureById(disclosureId),
        getBmwInspectionItems(vin, isPretermParam === 'true')
      ]).then(
        ([vehicleInfoResponse, usageResponse, disclosureResponse, bmwInspectionItemsResponse]) => {
          setVehicleInfo(vehicleInfoResponse);
          setUsage(usageResponse);
          setDisclosure(disclosureResponse);
          setBmwInspectionItems(bmwInspectionItemsResponse);
          reactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'loaded',
              message: 'Leif form is loaded'
            })
          );
        },
        () => {
          reactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'error',
              message: 'Leif form is unavailable'
            })
          );
        }
      );
    };
    fetchLEIFFormData();
  }, []);

  useEffect(() => {
    if (disclosure && usage) {
      const mileageCharge = calculateTotalMileageCharge(
        disclosure.odometer,
        usage?.contractMileage,
        usage?.excessMileageRate
      );
      setTotalMileageCharge(mileageCharge);
    }
  }, [usage, disclosure]);

  const damageSections = useMemo(
    () =>
      disclosure?.questionnaire?.items && bmwInspectionItems && usage?.newOrPreowned
        ? mapDamageSections(
            disclosure.responses,
            disclosure.questionnaire.items,
            disclosure.manheimAccountNumber,
            bmwInspectionItems,
            usage.newOrPreowned
          )
        : [],
    [disclosure, bmwInspectionItems, usage]
  );

  const leaseProtectionBenefitAmount = useMemo(
    () =>
      damageSections.length && usage?.leaseProtectionBenefit
        ? calculateLeaseProtectionBenefitAmount(damageSections)
        : 0,
    [damageSections, usage]
  );

  return (
    <LeifForm
      clientApp={clientApp}
      vehicleInfo={vehicleInfo}
      isPdfRendering={isPdfRendering}
      disclosureId={disclosure?.guid}
      currentMileage={disclosure?.odometer}
      usage={usage}
      dealershipName={dealershipName}
      damageSections={damageSections}
      totalMileageCharge={totalMileageCharge}
      leaseProtectionBenefitAmount={leaseProtectionBenefitAmount}
      isPreterm={isPreterm}
    />
  );
};
