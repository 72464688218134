import React from 'react';
import styled from 'styled-components';
import Message from '@prism/message';
import '@prism/styles/prism/message.min.css';

import { SectionHeader, ListContainer, FooterContainer, ListItem } from 'ewt/styles';

import { InspectionChargesDetailsType } from 'ewt/requests/chargesAPI/types';
import { roundToHundredth } from 'common/helpers/roundToHundredth';
import { formatCurrency } from 'common/helpers/formatCurrency';
import { TextContent } from '../helpers/Constants';
import { Footer } from './Footer';
import { getLinkForViewer } from 'ewt/helpers/getLinkForViewer';
import { getPriceView } from 'ewt/helpers/getPriceView';
import { ScreenSize } from '../../common/constants/ScreenSize';

interface Props {
  inspectionChargesDetails: InspectionChargesDetailsType;
  addedCharges?: InspectionChargesDetailsType;
  isInspectionUnverified?: boolean;
}

const priceStyle = {
  color: 'black',
  fontFamily: 'Roboto-Regular',
  fontSize: '13px',
  paddingRight: '10px'
};

const strikeThroughPriceStyle = {
  color: 'black',
  fontFamily: 'Roboto-Regular',
  fontSize: '13px',
  paddingRight: '10px',
  textDecoration: 'line-through'
};

export const WearAndUse: React.FC<Props> = ({ inspectionChargesDetails, addedCharges, isInspectionUnverified }) => {
  const totalPrice = formatCurrency(roundToHundredth(inspectionChargesDetails.totalPrice));

  return (
    <>
      <SectionHeader>Wear and Use</SectionHeader>
      {isInspectionUnverified && (
        <Message data-testid="unverified-message" title="Inspection Unverified" color="warning">
          Your inspection was not able to be verified by our professional inspector(s). The details below are based on
          what was initially reported. If you would like to schedule an in-person inspection, please call 833-596-0294.
        </Message>
      )}
      <ListContainer data-testid="list-container">
        <DesktopSizeContainer>
          <ListItem key="list-title" role="list-item-header">
            <DamageContainer key="list-title-container">
              <Half>
                <ListHeading>Location and damage</ListHeading>
                <QuantityHeading>QTY.</QuantityHeading>
              </Half>
              <Half>
                <ListHeading>Classification</ListHeading>
                <ListHeading>Estimated cost</ListHeading>
              </Half>
            </DamageContainer>
          </ListItem>
        </DesktopSizeContainer>
        {inspectionChargesDetails.charges.map(({ location, damages }, index) => (
          <ListItem key={index} role="list-item">
            <DamageLocation>{location}</DamageLocation>
            {damages.map((damage, index) => (
              <DamageContainer key={index}>
                <Half>
                  <DamageType>{damage.type}</DamageType>
                  <DesktopSizeQuantity>{damage.quantity || 1}</DesktopSizeQuantity>
                </Half>
                <Half>
                  <ChargeableContainer>
                    <MobileSizeQuantity>{damage.quantity || 1}</MobileSizeQuantity>
                    <ChargeableText>{`${damage.chargeable ? 'Excess' : 'Normal'} wear and use`}</ChargeableText>
                  </ChargeableContainer>
                  {getPriceView(damage.chargeable, damage.price, priceStyle, strikeThroughPriceStyle)}
                </Half>
              </DamageContainer>
            ))}
          </ListItem>
        ))}
      </ListContainer>
      {!!addedCharges?.charges.length && (
        <AdditionalDamagesSection>
          <SectionHeading>Added Damage(s)</SectionHeading>
          <SectionNote>
            Based on the remote inspector’s evaluation, the following item(s) were determined to be damages.
          </SectionNote>
          <AdditionalDamages>
            {addedCharges.charges.map(({ location, damages }, index) => (
              <ListItem key={index} role="list-item">
                <DamageLocation>{location}</DamageLocation>
                {damages.map((damage, index) => (
                  <DamageContainer key={index}>
                    <Half>
                      <DamageType>
                        {damage.type}{' '}
                        {damage.imageLinks?.length && (
                          <>
                            (<a href={getLinkForViewer(damage.imageLinks, damage.type)}>view</a>)
                          </>
                        )}
                      </DamageType>
                      <DesktopSizeQuantity>{damage.quantity || 1}</DesktopSizeQuantity>
                    </Half>
                    <Half>
                      <ChargeableContainer>
                        <MobileSizeQuantity>{damage.quantity || 1}</MobileSizeQuantity>
                        <ChargeableText>{`${damage.chargeable ? 'Excess' : 'Normal'} wear and use`}</ChargeableText>
                      </ChargeableContainer>
                      {getPriceView(damage.chargeable, damage.price, priceStyle, strikeThroughPriceStyle)}
                    </Half>
                  </DamageContainer>
                ))}
              </ListItem>
            ))}
          </AdditionalDamages>
        </AdditionalDamagesSection>
      )}
      <TotalEstimateContainer data-testid="total-estimate-container">
        <TotalEstimate>Total Estimate</TotalEstimate>
        <TotalEstimatePrice data-testid="total-estimate-price">{totalPrice}</TotalEstimatePrice>
      </TotalEstimateContainer>
      <FooterContainer data-testid="footer-container">
        <Footer content={TextContent.NormalWearAndUseFooter} />
      </FooterContainer>
    </>
  );
};
export const DesktopSizeContainer = styled.div`
  @media (max-width: ${ScreenSize.MobileLarge}px) {
    height: 0;
    overflow: hidden;
  }
`;

export const DamageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  break-inside: avoid-page;

  @media (max-width: ${ScreenSize.MobileLarge}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const DamageLocation = styled.div`
  color: #6a6a6a;
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  width: 80%;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const DamageType = styled.div`
  color: black;
  font-family: Roboto-Regular;
  font-size: 16px;
  line-height: 20px;
`;

const ChargeableContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const ChargeableText = styled.div`
  font-family: Roboto-Regular;
  font-size: 16px;
`;

const DesktopSizeQuantity = styled.div`
  width: 15%;
  color: black;
  font-family: Roboto-Regular;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;

  @media (max-width: ${ScreenSize.MobileLarge}px) {
    display: none;
  }
`;

const MobileSizeQuantity = styled.div`
  color: black;
  font-family: Roboto-Regular;
  font-size: 16px;
  text-align: left;
  padding-right: 10px;

  @media (min-width: ${ScreenSize.MobileLarge}px) {
    display: none;
  }
`;

export const TotalEstimateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const TotalEstimate = styled.div`
  color: black;
  font-family: Roboto-Regular;
  font-size: 20px;
  padding-right: 50px;
`;

export const TotalEstimatePrice = styled.div`
  color: black;
  font-family: BMWGroupCondensed-Bold;
  font-size: 16px;
  padding-right: 10px;
`;

const Half = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0.495;
  align-items: center;

  @media (max-width: ${ScreenSize.MobileLarge}px) {
    flex: 1;
  }
`;

const ListHeading = styled.div`
  color: #333333;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const QuantityHeading = styled.div`
  width: 15%;
  color: #333333;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: left;
`;

const AdditionalDamagesSection = styled.div`
  border-top: 1px solid lightgray;
  width: 100%;
  font-family: Roboto-Regular;
  font-size: 14px;
`;

const SectionHeading = styled.div`
  padding: 0px 8px;
  margin-top: 25px;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #333333;
`;

const SectionNote = styled.div`
  padding: 0px 8px;
  margin-top: 5px;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #6a6a6a;
`;

const AdditionalDamages = styled.div`
  background-color: #efefef;
  margin: 16px 0px;
  padding: 12px 8px;
`;
