import { BuildInfo, DDSInfo, VehicleInfo } from 'common/model/VehicleInformation';

import { fetchDdsInfo } from 'common/api/fetchDdsInfo';
import { mapDDSInfoToVehicleInfo } from '../ewt/helpers/mapDDSInfoToVehicleInfo';
import { deriveColorName } from './helpers/deriveColorsFromStyle';
import { ClientApp } from './model/ClientApp';
import { fetchBuildDataByVin } from 'common/api/fetchBuildDataByVin';
import { mapBuildInfoToVehicleInfo } from './mapBuildInfoToVehicleInfo';

const isDDSInfo = (payload: DDSInfo | BuildInfo): payload is DDSInfo => {
  return 'catalogItems' in payload;
};

export const deriveVehicleInfo = async (vin: string, clientApp: ClientApp): Promise<VehicleInfo> => {
  const request: Promise<DDSInfo | BuildInfo> =
    clientApp === ClientApp.Consumer ? fetchDdsInfo(vin) : fetchBuildDataByVin(vin);

  const payload = await request;

  const vehicleInfo = isDDSInfo(payload)
    ? mapDDSInfoToVehicleInfo({ payload, vin })
    : mapBuildInfoToVehicleInfo(payload);

  const colors =
    isDDSInfo(payload) && payload.catalogItems[0]?.colors ? deriveColorName(payload.catalogItems[0].colors) : undefined;

  return {
    ...vehicleInfo,
    vin,
    ...colors
  };
};
