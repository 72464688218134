import axios, { Method } from 'axios';
import { BuildInfo } from 'common/model/VehicleInformation';

export async function fetchBuildDataByVin(vin: string) {
  const config = {
    method: 'get' as Method,
    url: `/descriptions/build/id/vin/${vin}`
  };

  const response = await axios.request<BuildInfo>(config);
  return response.data;
}
