export type Channel =
  | 'manheim_concierge'
  | 'nextgear'
  | 'bmw'
  | 'manheim_employee'
  | 'carvana'
  | 'third_party_inspector'
  | 'us_bank';

export enum DisclosureType {
  ThirdParty = 'third_party',
  Seller = 'seller',
  Consumer = 'consumer'
}

export enum DisclosureSubtype {
  Preterm = 'preterm',
  LeaseEnd = 'lease_end',
  Remarketing = 'remarketing'
}

export type DisclosureQuestion = {
  id: string;
  text: string;
  buyerTranslation?: string;
  answers: DisclosureQuestionAnswer[];
  section: string;
  subSection: string;
  tags: string[];
  guid: string;
};

export type DisclosureQuestionAnswer = {
  value: string;
  connotation: number;
  tags?: string[];
  metaData?: {
    dsi?: {
      dsiLeaseProtection: boolean;
    };
  };
};

export type DisclosureResponse = {
  source: string;
  guid: string;
  values: string[];
  answers: DisclosureResponseAnswer[];
};

export type DisclosureResponseAnswer = {
  value: string;
  quantity?: number;
  notes?: string;
};

export type Disclosure = {
  href: string;
  ui: string;
  guid: string;
  type: DisclosureType;
  subtype: DisclosureSubtype;
  channel: Channel;
  mid: string;
  odometer: number;
  manheimAccountNumber: string;
  responses: DisclosureResponse[];
  unit: {
    href: string;
  };
  questionnaire?: {
    href: string;
    items: DisclosureQuestion[];
  };
  gfb?: {
    href: string;
  };
  submittedOn?: string;
  createdOn: string;
  updatedOn: string;
  deleted: boolean;
  createdBy: { source: string; sourceId: string };
  updatedBy: {
    source: string;
    sourceId: string;
  };
};
