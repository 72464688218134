import React from 'react';
import styled from 'styled-components';

export const LegalText = () => {
  return (
    <LegalTextContainer data-testid="legal-text">
      <br />
      <div>
        **The Disposition Fee is a charge included on the contract which is used to defray the costs of preparing and
        selling the vehicle at the end of term (either scheduled or early termination) if the vehicle is returned. It is
        not due if the customer purchases the vehicle. Your disposition fee will be waived if you or a member of your
        household lease or finance a new BMW through BMW Group Financial Services within 12 months of your termination
        date, or if you have any active account with BMW Group Financial Services. The disposition fee amount may vary
        based on state guidelines.
      </div>
      <br />
      <div>
        The total charges listed on this form may be subject to change if a vehicle is determined to have sustained
        damages not listed on this form. Examples of such damage may include but are not limited to any substantial body
        damage that exceeds the parameters listed on this form, missing, misaligned or broken components, any major
        mechanical problem, improper repairs, hail damage or a badly damaged interior (multiple burns, rips or water
        damage). In this case, a third-party inspector must evaluate the vehicle.
      </div>
      <br />
      <div>
        The inspection form must reflect the condition of the vehicle at turn-in. If turn-in condition is significantly
        different, a re-inspection is required. The signature of both the customer and inspector are required for
        validation; however, PLEASE NOTE that the inspection is not complete until inspector uploads the required images
        as required by BMW Financial Services.{' '}
        <b>
          By signing this form, the customer and inspector acknowledge that they have read and agree with the assessment
          of this vehicle as itemized and in accordance with the parameters defined on this form.
        </b>
      </div>
    </LegalTextContainer>
  );
};

const LegalTextContainer = styled.div`
  color: #666666;
  font-size: 19px;
  padding: 10px 8px;
`;
