import {
  CatalogItem,
  DDSInfo,
  isSuggestedFullInfo,
  isSuggestedOnlyCatalogId,
  VehicleInfo
} from 'common/model/VehicleInformation';

type Params = {
  payload: DDSInfo;
  vin: string;
};

export const mapDDSInfoToVehicleInfo = ({ payload, vin }: Params): VehicleInfo | undefined => {
  const { suggested, catalogItems } = payload;

  let catalogItem: Partial<CatalogItem> | undefined;
  if (isSuggestedOnlyCatalogId(suggested)) {
    catalogItem = payload.catalogItems.find(item => item.catalogId === suggested.catalogId);
  } else if (isSuggestedFullInfo(suggested)) {
    catalogItem = suggested;
  } else if (catalogItems.length) {
    catalogItem = catalogItems[0];
  }

  if (!catalogItem) {
    return undefined;
  }

  const model = {
    normalizedName: catalogItem.model?.normalizedName,
    marketingName: catalogItem.model?.marketingName
  };

  const trim = {
    normalizedName: catalogItem.trim?.normalizedName,
    ...(catalogItem.trim?.marketingName ? { marketingName: catalogItem.trim.marketingName } : {})
  };

  return {
    vin,
    make: catalogItem?.make || '',
    model: model.marketingName || model.normalizedName || '',
    trim: trim.marketingName || trim.normalizedName || '',
    year: catalogItem?.year || 0,
    bodyTypePrimary: catalogItem?.bodyDescription?.vehicleType || ''
  };
};
