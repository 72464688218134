import React, { useEffect, useState } from 'react';

import { getQueryParams } from 'common/utils/getQueryParams';
import { initializeAxios } from 'common/api/setup';
import { logger } from 'common/logger/logger';
import { setDisclosureIdMetadata, setVinMetadata } from 'common/logger/debugMetadata';
import { ClientApp } from 'common/model/ClientApp';
import { fetchUsageById } from 'common/api/fetchUsageById';
import { Usage } from 'common/model/Usage';
import { VehicleInfo } from 'common/model/VehicleInformation';
import { InspectionDetails, getBmwInspectionDetails } from 'bmw/common/api/requests/getBmwInspectionDetails';
import { WebViewWindow } from 'common/model/WebViewWindow';
import { EWTForm } from './EWTForm';
import { deriveVehicleInfo } from 'common/deriveVehicleInfo';

const clientApp = ClientApp.Consumer;
const defaultInspectionDetails: InspectionDetails = {
  damages: [],
  totalMileageCharge: 0,
  leaseProtectionBenefitAmount: 0,
  currentMileage: undefined
};

export const EWTFormContainer = () => {
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>();
  const [usage, setUsage] = useState<Usage>();
  const [disclosureId, setDisclosureId] = useState<string>();
  const [inspectionDetails, setInspectionDetails] = useState<InspectionDetails>(defaultInspectionDetails);
  const [dealershipName, setDealershipName] = useState<string>('');
  const [isPdfRendering, setIsPdfRendering] = useState<boolean>(false);

  useEffect(() => {
    logger.info('bmw:ewt:render');

    const { usageId, authToken, vin, dealershipName, disclosureId, isPdfRendering, authTokenType } = getQueryParams<{
      usageId: string;
      vin: string;
      authToken: string;
      dealershipName: string;
      disclosureId: string;
      isPdfRendering: string;
      authTokenType?: string;
    }>();
    document.title = vin && `Inspection Summary ${vin.substring(9)}`;

    setVinMetadata(vin);
    setDisclosureIdMetadata(disclosureId);
    setDisclosureId(disclosureId);

    const reactNativeWebView = (window as Partial<WebViewWindow & typeof globalThis>).ReactNativeWebView;

    if (authToken) {
      initializeAxios(authToken, clientApp, authTokenType);
    }
    setDealershipName(dealershipName || '');
    setIsPdfRendering(isPdfRendering === 'true');

    const fetchFormData = () => {
      Promise.all([
        deriveVehicleInfo(vin, clientApp),
        fetchUsageById(usageId),
        getBmwInspectionDetails(vin, disclosureId, usageId)
      ]).then(
        ([vehicleInfo, usageResponse, inspectionDetails]) => {
          setVehicleInfo(vehicleInfo);
          setUsage(usageResponse);
          if (inspectionDetails) {
            setInspectionDetails(inspectionDetails);
          }

          reactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'loaded',
              message: 'BMW EWT form is loaded'
            })
          );
        },
        () => {
          reactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'error',
              message: 'BMW EWT form is unavailable'
            })
          );
        }
      );
    };
    fetchFormData();
  }, []);

  return (
    <EWTForm
      clientApp={clientApp}
      vehicleInfo={vehicleInfo}
      isPdfRendering={isPdfRendering}
      disclosureId={disclosureId}
      usage={usage}
      dealershipName={dealershipName}
      damageSections={inspectionDetails.damages}
      currentMileage={inspectionDetails.currentMileage}
      totalMileageCharge={inspectionDetails.totalMileageCharge}
      leaseProtectionBenefitAmount={inspectionDetails.leaseProtectionBenefitAmount}
    />
  );
};
