export function omitKeysFromObject<TObj extends Record<string, unknown>, TKey extends string>(
  obj: TObj,
  keysToExclude: TKey[],
  checkArrays = false
): DeepOmit<TObj> | TObj {
  if (Array.isArray(obj) || !obj || typeof obj !== 'object') return obj;

  const tmp: Record<string, unknown> = {};

  Object.keys(obj).forEach((key: string) => {
    let currValue = obj[key];

    if (keysToExclude.includes(key as TKey)) return;

    if (checkArrays && Array.isArray(currValue)) {
      currValue = currValue.map(nestedObj => omitKeysFromObject(nestedObj as TObj, keysToExclude, checkArrays)); //filtering nested objects in arrays)
    } else {
      currValue = omitKeysFromObject(currValue as TObj, keysToExclude, checkArrays); //filtering nested objects)
    }

    tmp[key] = currValue;
  });

  return tmp as DeepOmit<TObj>;
}

type DeepOmit<T> = T extends Record<string, unknown>
  ? {
      // [P in keyof T as P extends S ? never : P]: DeepOmit<T[P], S>;
    }
  : T;
