import React from 'react';
import styled from 'styled-components';

import '@prism/styles/prism/message.min.css';

import { ListContainer, ListItem } from 'ewt/styles';
import { InspectionChargesDetailsType } from 'ewt/requests/chargesAPI/types';
import { getPriceView } from 'ewt/helpers/getPriceView';
import { roundToHundredth } from 'common/helpers/roundToHundredth';
import { formatCurrency } from 'common/helpers/formatCurrency';
import '../styles.css';

interface Props {
  inspectionChargesDetails: InspectionChargesDetailsType;
}

const priceStyle = {
  color: 'black',
  fontFamily: 'Inter',
  fontSize: '13px',
  paddingRight: '10px',
  fontWeight: 600
};

const strikeThroughPriceStyle = {
  color: 'black',
  fontFamily: 'Inter',
  fontSize: '13px',
  paddingRight: '10px',
  textDecoration: 'line-through'
};

export const WearAndUse: React.FC<Props> = ({ inspectionChargesDetails }) => {
  const totalPrice = formatCurrency(roundToHundredth(inspectionChargesDetails.totalPrice));

  return (
    <>
      <ListContainer data-testid="list-container">
        {inspectionChargesDetails.charges.map(({ location, damages }, index) => (
          <ListItem key={index} role="list-item">
            <DamageLocation>{location}</DamageLocation>
            {damages.map((damage, index) => (
              <DamageContainer key={index}>
                <RowContainer>
                  <DamageDetailsContainer>
                    <div>
                      <ChargeableText>{damage.type}</ChargeableText>
                    </div>
                    <ChargeableContainer>
                      <Quantity>{damage.quantity || 1}</Quantity>
                      <ChargeableText>{`${damage.chargeable ? 'Excess' : 'Normal'} wear and use`}</ChargeableText>
                    </ChargeableContainer>
                  </DamageDetailsContainer>
                  {getPriceView(damage.chargeable, damage.price, priceStyle, strikeThroughPriceStyle)}
                </RowContainer>
              </DamageContainer>
            ))}
          </ListItem>
        ))}
      </ListContainer>
      <TotalEstimateContainer data-testid="total-estimate-container">
        <TotalEstimate>Total Estimate</TotalEstimate>
        <TotalEstimatePrice data-testid="total-estimate-price">{totalPrice}</TotalEstimatePrice>
      </TotalEstimateContainer>
    </>
  );
};

export const DamageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  break-inside: avoid-page;
`;

export const DamageDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 7px;
`;

const ChargeableContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7px;
`;

const Quantity = styled.div`
  color: black;
  font-family: Inter;
  font-size: 12px;
  text-align: left;
  margin-right: 2px;
  line-height: 18px;
`;

export const TotalEstimate = styled.div`
  color: black;
  font-family: Inter;
  font-size: 12px;
  padding-right: 12px;
  font-weight: 700;
`;

export const TotalEstimatePrice = styled.div`
  color: black;
  font-family: Inter;
  font-size: 12px;
  padding-right: 18px;
  font-weight: 700;
`;

const ChargeableText = styled.div`
  font-family: Inter;
  font-size: 12px;
  color: black;
  line-height: 18px;
`;

export const DamageLocation = styled.div`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  padding-bottom: 3px;
  border-bottom: 1px solid #d9d9d9;
  padding-left: 7px;
`;

const TotalEstimateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #f5f5f6;
  border-top: 1px solid #666666;
  height: 50px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: flex-end;
`;
