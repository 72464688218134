import React from 'react';
import Icon from '@prism/icon';

import { HeaderContainer, FooterContainer, SummaryContainer, InfoContainer, InfoContent } from 'ewt/styles';

import { InspectionChargesDetailsType } from 'ewt/requests/chargesAPI/types';
import { ExpandedDisclosure } from 'common/model/Disclosure/ExpandedDisclosure';

import { Header } from './Header';
import { Footer } from './Footer';
import { Overview } from './Overview';

import { TextContent } from '../helpers/Constants';
import { WearAndUse } from './WearAndUse';
import { VerificationStatus } from './VerificationStatus';
import { colors } from 'common/styles/colors';
import { VehicleInfo } from 'common/model/VehicleInformation';

interface Props {
  title: string;
  expandedDisclosure?: ExpandedDisclosure;
  inspectionChargesDetails?: InspectionChargesDetailsType;
  vehicleInfo?: VehicleInfo;
  isInspectionVerified?: boolean;
  addedCharges?: InspectionChargesDetailsType;
}

export const InspectionSummary: React.FC<Props> = ({
  title,
  isInspectionVerified,
  expandedDisclosure,
  inspectionChargesDetails,
  vehicleInfo,
  addedCharges
}) => {
  if (!expandedDisclosure || !inspectionChargesDetails || !vehicleInfo) {
    return null;
  }

  return (
    <SummaryContainer data-testid={title}>
      <HeaderContainer>
        <Header documentName={title} />
        {isInspectionVerified !== undefined && <VerificationStatus verified={isInspectionVerified} />}
      </HeaderContainer>
      <Overview vehicleInfo={vehicleInfo} unit={expandedDisclosure.unit} disclosure={expandedDisclosure} />
      <WearAndUse
        inspectionChargesDetails={inspectionChargesDetails}
        addedCharges={addedCharges}
        isInspectionUnverified={isInspectionVerified === false}
      />
      <FooterContainer>
        {isInspectionVerified && (
          <InfoContainer>
            <Icon glyph="info" style={{ color: colors.manheimPrimaryBlueDark }} />
            <InfoContent>{TextContent.DamagesVerified}</InfoContent>
          </InfoContainer>
        )}
        <Footer content={TextContent.BottomFooterText} />
      </FooterContainer>
    </SummaryContainer>
  );
};
