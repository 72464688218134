import { Colors } from 'common/model/VehicleInformation';
import { findInstalledEquipment } from './findInstalledEquipment';

type DerivedColorsParams = {
  exteriorColor: string | undefined;
  interiorColor: string | undefined;
};

export const deriveColorName = (colors: Colors): DerivedColorsParams => {
  const installedExteriorColor = findInstalledEquipment(colors.exterior ?? []);
  const installedInteriorColor = findInstalledEquipment(colors.interior ?? []);
  return {
    exteriorColor: installedExteriorColor?.oemName ?? installedExteriorColor?.normalizedName ?? undefined,
    interiorColor: installedInteriorColor?.oemName ?? installedInteriorColor?.normalizedName ?? undefined
  };
};
