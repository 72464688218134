import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { QueryParamProvider } from 'use-query-params';

import { createPreserveQueryHistory } from 'common/utils/createPreserveQueryHistory';
import { LeifFormContainer as BmwLeifForm } from 'bmw/leif/LeifFormContainer';
import { EWTFormContainer as BmwEwtForm } from 'bmw/ewt/EWTFormContainer';
import { EWTFormContainer as TFSEwtForm } from 'tfs/ewt/EWTFormContainer';
import { ImageViewer } from 'display-image/ImageViewer';
import { EWTContainer } from './ewt/EWTContainer';

const history = createPreserveQueryHistory<{}, never>(createBrowserHistory)();

function App() {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/bmw-leif" />
          </Route>
          <Route path={['/bmw-leif']}>
            <BmwLeifForm />
          </Route>
          <Route path={['/bmw-ewt']}>
            <BmwEwtForm />
          </Route>
          <Route path={['/usbank-ewt', '/nissan-ewt', '/basic']}>
            <EWTContainer />
          </Route>
          <Route path="/tfs-ewt">
            <TFSEwtForm />
          </Route>
          <Route path="/display-images">
            <ImageViewer />
          </Route>
          <Route path="/error">
            <p>Not found</p>
          </Route>
          <Redirect to="/error" />
        </Switch>
      </QueryParamProvider>
    </Router>
  );
}

// eslint-disable-next-line no-restricted-syntax
export default App;
