import styled from 'styled-components';

import { ScreenSize } from 'common/constants/ScreenSize';

export const VehiclePropertiesListContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  width: 100%;
  padding: ${props => (props.isConsumerInspection ? '16px 0' : '12px 8px')};
  display: flex;
  flex-direction: column;

  @media print, screen and (min-width: ${ScreenSize.Mobile}px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${props => (props.isConsumerInspection ? 'start' : 'space-between')};
  }
`;

export const PropertiesListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 20px 0px;
  width: 100%;

  @media print, screen and (min-width: ${ScreenSize.MobileLarge}px) {
    flex-direction: column;
    width: 33%;
    padding: 10px 0px 10px 0px;
    justify-content: flex-start;
  }
  @media print, screen and (min-width: ${ScreenSize.Tablet}px) {
    width: 25%;
  }
`;

export const ConsumerStylePropertiesListItem = styled(PropertiesListItem as any)`
  padding: 0 0 8px;

  @media print, screen and (min-width: ${ScreenSize.Tablet}px) {
    width: 30%;
  }
`;

export const PropertiesListItemLastInRow = styled.div<{
  isConsumerInspection?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => (props.isConsumerInspection ? '0 0 8px' : '10px 0px 20px 0px')};
  width: 100%;

  @media print, screen and (min-width: ${ScreenSize.MobileLarge}px) {
    flex-direction: column;
    width: 33%;
    padding: 10px 0px 10px 0px;
    justify-content: flex-start;
  }
  @media print, screen and (min-width: ${ScreenSize.Tablet}px) {
    width: 50%;
  }
`;

export const PropertiesListItemWholeRow = styled.div<{
  isConsumerInspection?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => (props.isConsumerInspection ? '0 0 8px' : '10px 0px 20px 0px')};
  width: 100%;

  @media print, screen and (min-width: ${ScreenSize.MobileLarge}px) {
    flex-direction: column;
    width: 100%;
    padding: 10px 0px 10px 0px;
    justify-content: flex-start;
  }
  @media print, screen and (min-width: ${ScreenSize.Tablet}px) {
    width: 25%;
  }
`;

export const TitleText = styled.div`
  color: #666666;
  font-size: 19px;
  text-transform: uppercase;
`;

export const ConsumerStyleTitleText = styled.div<{
  fontWeight?: string;
}>`
  color: #6a6a6a;
  font-size: 14px;
  ${props => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
`;

export const ConsumerPDFStyleTitleText = styled.div<{
  fontWeight?: string;
}>`
  color: #6a6a6a;
  font-size: 14px;
  font-family: BMWGroupCondensed-Regular;
  ${props => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
`;

export const ValueText = styled.div<{
  fontWeight?: string;
}>`
  color: black;
  font-size: 19px;
  ${props => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
`;

export const ConsumerStyleValueText = styled.div`
  color: black;
  font-size: 16px;
`;

export const ConsumerPDFStyleValueText = styled.div`
  color: black;
  font-size: 16px;
  font-family: BMWGroupCondensed-Regular;
`;

export const getListElements = ({
  isConsumerInspection,
  isPdfRendering
}: {
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}) =>
  isConsumerInspection
    ? {
        Title: isPdfRendering ? ConsumerPDFStyleTitleText : ConsumerStyleTitleText,
        Value: isPdfRendering ? ConsumerPDFStyleValueText : ConsumerStyleValueText,
        PropertiesListItem: ConsumerStylePropertiesListItem
      }
    : { Title: TitleText, Value: ValueText, PropertiesListItem };
