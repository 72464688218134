import React from 'react';
import { ConditionReport as CR } from 'cr_popout_ui';
import styled from 'styled-components';
import { HeaderContainer } from 'ewt/styles';
import { Header } from 'ewt/components/Header';
import './ConditionReport.css';

export const ConditionReport = ({ disclosureId, env }: { disclosureId?: string; env: string }) => {
  if (!disclosureId) return null;

  return (
    <PageContainer>
      <HeaderContainer>
        <Header documentName="Condition Report" />
      </HeaderContainer>
      <CR
        config={{
          rootElementId: 'condition-report',
          environment: env,
          disclosureId: disclosureId,
          onlySections: {
            inspection: '*'
          }
        }}
      />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  break-before: page;
  width: 100%;
`;
