import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as descriptions from 'bmw/common/constants/checkBoxDescriptions';
import { fetchDisclosureDocumentsByDisclosureId } from 'bmw/common/api/requests/fetchDisclosureDocumentsByDisclosureId';
import { CheckboxWithDescription } from 'bmw/common/components/CheckboxWithDescription';
import { BMWPdfHeader } from 'bmw/common/helpers/globalStyles';
import { DisclosureDocument, DisclosureDocumentType } from 'bmw/common/model/DisclosureDocument';

import { Signature } from './Signature';

interface Props {
  disclosureId: string;
}

export const AcknowledgmentSection: React.FC<Props> = ({ disclosureId }) => {
  const [disclosureDocument, setDisclosureDocument] = useState<DisclosureDocument>();

  useEffect(() => {
    const fetchAcknowledgmentData = async () => {
      const disclosureDocuments = await fetchDisclosureDocumentsByDisclosureId(disclosureId || '');
      const disclosureDocument = disclosureDocuments.find(
        document => document.type === DisclosureDocumentType.CustomerPretermInspectionForm
      );

      setDisclosureDocument(disclosureDocument);
    };
    fetchAcknowledgmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <AcknowledgmentContainer>
        <Header>Customer Agreements</Header>
        {disclosureDocument && (
          <>
            <CheckboxWithDescription
              checked={disclosureDocument.dmeAcknowledgement || true}
              description={descriptions.ACKNOWLEDGEMENT_CHECKBOX_AGREEMENT}
              isConsumerInspection
            />
            <AcknowledgeText>{descriptions.PRETERM_ACKNOWLEDGEMENT_CHECKBOX_DESCRIPTION}</AcknowledgeText>
            <CheckboxWithDescription
              checked={disclosureDocument.alterationsAddlCharges || true}
              description={descriptions.CUSTOMER_CERTIFICATION_CHECKBOX_AGREEMENT}
              isConsumerInspection
            />
            <AcknowledgeText>{descriptions.PRETERM_CUSTOMER_CERTIFICATION_CHECKBOX_DESCRIPTION}</AcknowledgeText>
          </>
        )}
        <>
          <Header>Customer Signature</Header>
          <Signature
            signature={disclosureDocument?.clientSignature.replace('\n', '') || ''}
            signatureDate={disclosureDocument?.clientSignatureDate || ''}
          />
        </>
      </AcknowledgmentContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  break-inside: avoid-page;
  background-color: #ededee;

  @media print {
    height: 100vh;
  }
`;

const AcknowledgmentContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 10px auto;
  padding-bottom: 15px;

  @media print, screen and (max-width: 1200px) {
    max-width: 80%;
  }

  @media print, screen and (max-width: 960px) {
    max-width: 680px;
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 15px 15px 0;
  ${BMWPdfHeader}
`;

const AcknowledgeText = styled.p`
  font-family: BMWGroupCondensed-Regular;
  font-size: 14px;
  margin: 20px 0px 20px 15px;
`;
