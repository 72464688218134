import { ValuationDetails } from 'bmw/common/model/ValuationDetails';
import { TechnicalSpecifications } from './TechnicalSpecifications';

export type StateCode = {
  code: string;
  name: string;
};

type Country = {
  code: string;
  name: string;
};

type Model = {
  normalizedModelId: number;
  normalizedName: string;
  marketingName: string;
  modelGroup: string;
  availability: string;
  modelCode: string;
};

type Trim = {
  normalizedTrimId: number;
  normalizedName: string;
  marketingName: string;
};

type Price = {
  amount: string;
  unitOfMeasure: string;
};

type Engine = {
  primaryDescription: string;
  extendedDescription: string;
  displacement: Price;
  camshaft: string;
  blockType: string;
  cylinderCount: number;
  valveCount: number;
  fuelIntake: string;
  fuelType: string;
  fuelCategory: string;
  aspiration: string;
  manufacturer?: string;
  stroke?: string;
};

type Transmission = {
  primaryDescription: string;
  extendedDescription: string;
  type: string;
  gearCount: number;
  clutchType?: string;
  manufacturer?: string;
};

type Drivetrain = {
  type: string;
  driveType?: string;
  finalDrive?: string;
  wheelCount?: number;
};

export type Powertrain = {
  engine: Engine;
  transmission: Transmission;
  drivetrain: Drivetrain;
};

type BodyDescription = {
  segment: string;
  vehicleType: string;
  marketingBodyStyle: string;
  primaryBodyStyle: string;
  primaryBodyStyleId: number;
  passengerDoors: string;
  rearWheelConfig: {
    type: string;
  };
};

export type DDSColor = {
  oemName: string;
  normalizedName: string;
  oemOptionCode: string;
  rgbHex: string;
  installedReason: string;
  installed: boolean;
};

type InteriorColor = DDSColor & {
  materialType: string[];
};

type ExteriorColor = DDSColor & {
  isPrimary: boolean;
};

export type Colors = {
  interior?: InteriorColor[];
  exterior?: ExteriorColor[];
};

type Equipment = {
  id: number;
  availability: string;
  classification: string;
  primaryDescription: string;
  pricing: {
    msrp: Price;
    invoice: Price;
  };
  installedReason: string;
};

type OptionalEquipment = {
  id: number;
  mutuallyExclusiveEquipmentIds: number[];
  availability: string;
  classification: string;
  primaryDescription: string;
  generics: {
    name: string;
    id: number;
  }[];
  pricing: {
    msrp: Price;
    invoice: Price;
  };
};

type ConsumerInformation = {
  type: string;
  content: {
    [key: string]: string;
  };
}[];

type VehicleColor = {
  colorType: string;
  genericDescription: string;
  manufacturerDescription: string;
};

type VehiclePackage = {
  descriptionType?: string;
  buildCode: string;
  name: string;
  infoSource?: string;
  definingSource?: string;
  href: string;
};

export type BuildInfo = {
  manheimStandardDescription: string;
  vin: string;
  year?: number;
  make?: string;
  model?: string;
  trim?: string;
  bodyTypePrimary?: string;
  styleName?: string;
  countryCode: undefined | string;
  mmr?: ValuationDetails;
  engineType: string;
  transmission: string;
  vehicleSubType: string;
  vehicleSubTypeCode: string;
  engineDisplacement: string;
  numberOfDoors: undefined | number;
  styleID: undefined | number;
  styleNameWithoutTrim?: string;
  manufacturerTrim?: string;
  drivetrain: string;
  engineFuelType: string;
  packages?: VehiclePackage;
  color?: [VehicleColor];
  styles?: {
    count: number;
    style: StyleInfo[];
    selectedStyleIndex: number;
  };
};

export type CatalogItem = {
  id: number;
  classification: string;
  country: Country;
  countryOfOrigin: string;
  title: string;
  year: number;
  manufacturer: string;
  make: string;
  model: Model;
  trim: Trim;
  vehicleAvailability: string;
  marketingName: string;
  sparse: boolean;
  invoice: Price;
  msrp: Price;
  trueBasePrice: boolean;
  destination: Price;
  powertrain: Powertrain;
  bodyDescription: BodyDescription;
  colors: Colors;
  installedEquipment: Equipment[];
  optionalEquipment: OptionalEquipment[];
  technicalSpecifications: TechnicalSpecifications;
  consumerInformation: ConsumerInformation;
  asBuiltMsrp: Price;
  makeId: number;
  thirdPartyIdentifiers: {
    [key: string]: {
      [key: string]: any;
    }[];
  };
  styleId: number;
  buildDataProvidedDescriptions: {
    manufacturerTrim: string;
  };
  catalogId: string;
};

export type StyleInfo = CatalogItem[];

export type Color = {
  name?: string;
  genericName?: string;
};

export type VehicleInfo = {
  make?: string;
  model?: string;
  year?: number;
  trim?: string;
  vin: string;
  bodyTypePrimary?: string;
  interiorColor?: string;
  exteriorColor?: string;
};

export type SuggestedFullInfo = {
  catalogItemId?: number;
  catalogItemIds?: number[];
  colors?: Colors;
  sparse?: boolean;
  countryOfOrigin?: string;
  year?: number;
  manufacturer?: string;
  make?: string;
  model?: Model;
  trim?: Trim;
  powertrain?: Powertrain;
  bodyDescription?: BodyDescription;
  vehicleAvailability?: string;
  catalogId?: string;
};

export type SuggestedOnlyCatalogId = {
  catalogId?: string;
};

export type Suggested = SuggestedFullInfo | SuggestedOnlyCatalogId;

export const isSuggestedFullInfo = (suggested?: Suggested): suggested is SuggestedFullInfo => {
  const condition = suggested && 'model' in suggested && !('catalogId' in suggested);
  return Boolean(condition);
};

export const isSuggestedOnlyCatalogId = (suggested?: Suggested): suggested is SuggestedOnlyCatalogId => {
  const condition = suggested && 'catalogId' in suggested;
  return Boolean(condition);
};

export type DDSInfo = {
  vin: string;
  count: number;
  tags: string[];
  suggested: Suggested;
  source: {
    catalogSource: string;
    suggestedIncludesOverrides: boolean;
    suggestedSource: string;
  };
  catalogItems: CatalogItem[];
  dataSource: string;
  customInteriorColor?: string;
  customInteriorGenericColor?: string;
  customExteriorColor?: string;
  customExteriorGenericColor?: string;
};
