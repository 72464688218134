import axios from 'axios';
import { DDSInfo } from 'common/model/VehicleInformation';

export async function fetchDdsInfo(vin: string): Promise<DDSInfo> {
  const response = await axios.get<DDSInfo>(`/designated-description/decode/vin/${vin}`, {
    headers: {
      'Application-Source': 'next-inspect-consumer'
    }
  });

  return response.data;
}
